.error404 {
	color: var(--black);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	line-height: 35px;
	padding: 40px 27px 80px;

	&-container {
		margin: auto;
	}

	&-title {
		font-size: 40px;
		line-height: 35px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	&-text {
		font-size: 14px;
		line-height: 24px;
		font-weight: 200;
		margin-bottom: 54px;
	}

	&-link {
		display: inline-block;
		color: inherit;
		font-size: 14px;
		line-height: 15px;
		padding: 10px 30px;
		border: 1px solid var(--black);
		margin-bottom: 30px;
	}

	&-svg {
		width: 112px;
		height: 65px;
		margin: 0 auto 10px;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

@media (--media-desktop) {
	.error404 {
		padding: 0;

		&-svgLogo {
			display: none;
		}

		&-title {
			font-size: 50px;
		}

		&-text {
			line-height: 24px;
			margin-bottom: 60px;
			max-width: 330px;
		}

		&-svg {
			width: 270px;
			height: 155px;
			margin-bottom: 60px;
		}

		&-link {
			margin-bottom: 0;
		}
	}
}
